import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import type { Report } from '../types/Report'
import { ReportData } from '../types/ReportData'
import type { Location } from '../types/Location'
import type { ArtifactType } from '../types/ArtifactType'
import {v4} from 'uuid';
import type { SearchQuery } from '../types/SearchQuery';
import type { SearchQueryUsage } from '../types/SearchQueryUsage';
import { DataTreeNode } from '../types/DataTreeNode'
import { ArtifactSelection } from '../types/ArtifactSelection'
import { Visualization } from '../types/Visualization'

// Define a type for the slice state
interface ReportsState {
  reports: Report[],
  search_queries: SearchQuery[]|null,
  selectedReportId: string|null,
  selectedVisId: string|null
}

// Define the initial state using that type
const initialState: ReportsState = {
  reports: [],
  search_queries: null,
  selectedReportId: null,
  selectedVisId :null
}

export const reportsSlice = createSlice({
  name: 'reportsData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    load: (state, action: PayloadAction<Report>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload.id
      });
      if (index !== -1) {
        state.reports[index] = action.payload;
      } else {
        state.reports.push(action.payload)
      }
    },
    loadData: (state, action: PayloadAction<[string, Partial<Report>]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        const report = state.reports[index];
        state.reports[index] = { ...report, reportData: (action.payload[1]?.reportData || [])};
      }
    },
    loadAll: (state, action: PayloadAction<Report[]>) => {
      if (state.reports.length == 0){
        state.reports = action.payload
      } else {
        action.payload.forEach(report => {
          const index = state.reports.findIndex( ( oldReport: Report ) => {
            return oldReport.id === report.id
          });
          if (index !== -1) {
            const oldReport = state.reports[index];
            state.reports[index] = { ...oldReport, ...report};
          } else {
            state.reports.push(report)
          }       
        })
      }
    },
    loadReportSearchQueryUsages: (state, action: PayloadAction<[string, SearchQueryUsage[]]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1){
        state.reports[index].config.search_query_usages = action.payload[1]
        state.reports[index].config.search_query_usages_saved = true
      }
    },
  
    removeReportSearchQueryUsages: (state, action: PayloadAction<[string, string]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1){
        state.reports[index].config.search_query_usages = 
          state.reports[index].config.search_query_usages?.filter( x => x.search_query_id !== action.payload[1]) || []
        state.reports[index].config.search_query_usages_saved = false
      }
    },

    addReportSearchQueryUsages: (state, action: PayloadAction<[string, SearchQueryUsage]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1){
        if (!state.reports[index].config.search_query_usages) {
          state.reports[index].config.search_query_usages = []
        }
        state.reports[index].config.search_query_usages = [...(state.reports[index].config?.search_query_usages|| []), action.payload[1]];
        state.reports[index].config.search_query_usages_saved = false
      }
    },

    updateReportSearchQueryUsages: (state, action: PayloadAction<[string, SearchQueryUsage]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1){
        const squIndex = state.reports[index].config.search_query_usages?.findIndex(x => x.search_query_id === action.payload[1].search_query_id) || -1
        if (squIndex !== -1){
          let squs: SearchQueryUsage[] = state.reports[index].config.search_query_usages || []
          squs[squIndex] = action.payload[1]
          state.reports[index].config.search_query_usages_saved = false
          squs.sort((a, b) => {
            const a_order = a.order ? a.order : -1
            const b_order = b.order ? b.order : -1
              if (a_order > b_order)
                return 1
              if (b_order > a_order)
                return -1
              return 0
            })
          state.reports[index].config.search_query_usages = squs
        }
      }
    },

    updateReportArtifactSelections: (state, action: PayloadAction<[string, ArtifactSelection[]]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1){
        state.reports[index].artifactSelections = action.payload[1]
      }
    },
    updateReportArtifactSelectionChecksums: (state, action: PayloadAction<[string, string, string[]]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1 && Array.isArray(state.reports[index].artifactSelections)){
        let artifactSelections = [...(state.reports[index].artifactSelections || [])]
        const aSIndex = artifactSelections.findIndex((selection) => {
          return selection.uuid == action.payload[1]
        })
        if(aSIndex !== -1) {
           artifactSelections[aSIndex] = { ...artifactSelections[aSIndex], checksums: action.payload[2]}
        }
        state.reports[index].artifactSelections = artifactSelections
      }
    },


    loadSearchQueries: (state, action: PayloadAction<SearchQuery[]>) => {
      state.search_queries = action.payload
    },

    updateSearchQuery: (state, action: PayloadAction<SearchQuery>) => {
      const index = state.search_queries?.findIndex(x => x.id == action.payload.id) || -1
      if (index !== -1) {
        let sqs = state.search_queries || []
        sqs[index] = action.payload
        state.search_queries = sqs
      }
    },

    addSearchQuery: (state, action: PayloadAction<SearchQuery>) => {
      state.search_queries?.push(action.payload)
    },
    removeReport: (state, action: PayloadAction<string>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload
      });
      if (index !== -1){
        state.reports.splice(index, 1)
      }
    },
    create: (state, action: PayloadAction<Report>) => {
      state.reports.push({ ...action.payload, id: v4()})
    },
    select: (state, action: PayloadAction<string>) =>  {
      state.selectedReportId = action.payload
      state.selectedVisId = null
    },
    updateLocations: (state, action: PayloadAction<[string, Array<Location>]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].config.locations = action.payload[1]
        state.reports[index].config.saved = false
      }
    },
    updateLocationPaths: (state, action: PayloadAction<[string, string[]]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].config.locationPaths = action.payload[1]
        state.reports[index].config.saved = false
      }
    },
    updateArtifactTypes: (state, action: PayloadAction<[string, Array<ArtifactType>]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].config.artifactTypes = action.payload[1]
        state.reports[index].config.saved = false
      }
    },
    updateDateRange: (state, action: PayloadAction<[string, [string|null, string|null]]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].config.dateRange = action.payload[1]
        state.reports[index].config.saved = false
      } 
    },
    updateQuery: (state, action: PayloadAction<[string, string]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].config.query = action.payload[1]
        state.reports[index].config.saved = false
      } 
    },
    updateCompQueries: (state, action: PayloadAction<[string, string[]]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].config.comparisons = action.payload[1]
        state.reports[index].config.saved = false
      } 
    },
    updateName: (state, action: PayloadAction<[string, string]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].config.name = action.payload[1]
        state.reports[index].config.saved = false
      } 
    },
    updateStatus: (state, action: PayloadAction<[string, string]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].dataStatus = action.payload[1]
      } 
    },
    updateReportData: (state, action: PayloadAction<[string, Report]>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].reportData = action.payload[1].reportData
      } 
    },
    updateReportUsers: (state, action: PayloadAction<[string, string[]]>) => {
     const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        state.reports[index].users = action.payload[1]
      }  
    },
    createVis: (state, action: PayloadAction<Partial<Visualization>>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === state.selectedReportId
      });
      if (index !== -1) {
        state.reports[index]?.visualizations.push({...action.payload, id: v4()} as Visualization)
        state.reports[index].config.saved = false
      }
    },
    removeVis: (state, action: PayloadAction<Visualization>) => {
      const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === state.selectedReportId
      });
      if (index !== -1) {
        const visIndex = state.reports[index].visualizations.findIndex( v => {
          return v.id === action.payload.id
        });
        if (visIndex !== -1){
          state.reports[index].visualizations.splice(visIndex, 1)
          state.reports[index].config.saved = false
        }
      }
    },
    updateVis: (state, action: PayloadAction<[string, Visualization]>) => {
       const index = state.reports.findIndex( ( report: Report ) => {
        return report.id === action.payload[0]
      });
      if (index !== -1) {
        const visIndex = state.reports[index].visualizations.findIndex( v => {
          return v.id === action.payload[1].id
        });
        if (visIndex !== -1){
          state.reports[index].visualizations[visIndex] = action.payload[1]
          state.reports[index].config.saved = false
        }
      }    
    },
    selectVis: (state, action: PayloadAction<string>) => {
      state.selectedVisId = action.payload
    }
  },
})

export const { updateReportArtifactSelectionChecksums, removeReportSearchQueryUsages, addReportSearchQueryUsages, updateReportSearchQueryUsages, addSearchQuery, updateSearchQuery, loadReportSearchQueryUsages, loadSearchQueries, removeVis, removeReport, updateReportUsers, updateCompQueries, updateReportData, updateStatus, create, createVis,  updateName, updateQuery, updateDateRange, updateArtifactTypes, updateLocations, updateLocationPaths, loadAll, load, select, updateVis, selectVis, loadData, updateReportArtifactSelections  } = reportsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectReports = (state: RootState) => state.reportsData.reports

export const selectSearchQueries = (state: RootState) => state.reportsData.search_queries


export const selectedReport = (state: RootState) => {
  if (state.reportsData.selectedReportId) {
    return state.reportsData.reports.find( r => r.id == state.reportsData.selectedReportId)
  } else {
    return null;
  }
};

export const selectedReportId = (state: RootState) => {
  return state.reportsData.selectedReportId
};

export const selectedReportDataStatus = createSelector([selectedReport], (report) => {
  if (report) {
    return report.dataStatus
  } else {
    return null
  }
})

export const selectedReportSearchQueryUsages = createSelector([selectedReport], (report) => {
  if (report) {
    return report.config.search_query_usages
  } else {
    return null
  }
})




export const selectedVisId = (state: RootState) => {
  return state.reportsData.selectedVisId
};

export const selectedVis = createSelector([selectedReport, selectedVisId], (report, selectedVisId) => {
    if (report && selectedVisId) {
      return report.visualizations.find( v => v.id == selectedVisId)
    } else {
      return null;
    } 

})


export const selectedArtifactSelections = createSelector([selectedReport, selectedVisId],
  (report, selectedVisId) => {
     if (report && report.artifactSelections && selectedVisId) {
      return report.artifactSelections.filter( sel => sel.visualizationUUID == selectedVisId)
    } else {
      return null;
    }
   
  }
)
export const selectSeriesById = (state, visId:string|undefined, id:string) => {
  if (state.reportsData.selectedReportId) {
    const report =  state.reportsData.reports.find( r => r.id == state.reportsData.selectedReportId)
    if (report && visId) {
      const vis = report.visualizations.find( v => v.id == visId)
      if (vis) {
        return vis.series.find(s => s.id == id)
      } else {
        return null
      }
    } else {
      return null;
    } 
  } else {
    return null;
  }
}

export const selectedSeries = createSelector(
  [selectSeriesById], (series) => series)


export const selectSearchQueriesSaved = createSelector([selectSearchQueries], (searchQueries) => {
  const changes = searchQueries?.filter(sq => sq.local || sq.updated) || []
  return changes.length == 0 
})


export const selectedLocationPaths = createSelector([selectedReport], (report) => {
  if (report) {
    return report.config.locationPaths
  } else {
    return null
  }
})

export const selectedVisualizations = createSelector([selectedReport], (report) => {
  if(report) {
    
    return report.visualizations
  } else {
    return null
  }
})

export const selectedReportData = createSelector([selectedReport], (report) => {
  if(report) {
    return report.reportData
  } else {
    return null
  }
})
export const selectedReportUsers = createSelector([selectedReport], (report) => {
  if(report && report.users) {
    return report.users
  } else {
    return []
  }
})

const dataSetOptions = (reportDataElet) => {
  if (reportDataElet && reportDataElet.datasets) {
    return reportDataElet.datasets.map( ds => {
      return {
        id: ds.id,
        label: ds.name,
        isSelected: false,
        rdId: reportDataElet.id
      }
    });

  } else {
    return [];
  }
}

export const selectedDataSetTree = createSelector([selectedReportData], (reportData) => {
    let nodes:DataTreeNode[] = []
    reportData?.forEach((rd, index) => {
      let nodeIndices:number[] = []
      let indicesLength = rd.name.split(" - ").length
      rd.name.split(" - ").forEach((part, partIndex) => {

        let options = []
        if (partIndex + 1 == indicesLength) {
          options = dataSetOptions(rd)
        }

        if (partIndex == 0) {
          nodeIndices[partIndex] = nodes.findIndex(x => x.label === part)
          if (nodeIndices[partIndex] == -1){
            nodes.push({
              isExpanded: false,
              id: part,
              label: part,
              childNodes: options
            })
            nodeIndices[partIndex] = nodes.length - 1
          }
        }
        if (partIndex == 1){
          nodeIndices[partIndex] = nodes[nodeIndices[partIndex - 1]]
            .childNodes.findIndex(x => x.label === part)
          if (nodeIndices[partIndex] == -1){
            nodes[nodeIndices[partIndex - 1]].childNodes.push({
              isExpanded: false,
              id: part,
              label: part,
              childNodes: options
            })
            nodeIndices[partIndex] = nodes[nodeIndices[partIndex - 1]]
            .childNodes.length - 1
          }
        }
        if (partIndex == 2){
          nodeIndices[partIndex] = nodes[nodeIndices[partIndex - 2]]
            .childNodes[nodeIndices[partIndex - 1]]
            .childNodes.findIndex(x => x.label === part)
          if (nodeIndices[partIndex] == -1){
            nodes[nodeIndices[partIndex - 2]]
              .childNodes[nodeIndices[partIndex - 1]]
              .childNodes.push({
                isExpanded: false,
                id: part,
                label: part,
                childNodes: options
              })
            nodeIndices[partIndex] = nodes[nodeIndices[partIndex - 2]]
            .childNodes[nodeIndices[partIndex - 1]]
            .childNodes.length - 1
          }
        }
        if (partIndex == 3){

          nodeIndices[partIndex] = nodes[nodeIndices[partIndex - 3]]
            .childNodes[nodeIndices[partIndex - 2]]
            .childNodes[nodeIndices[partIndex - 1]]
            .childNodes.findIndex(x => x.label === part)
          if (nodeIndices[partIndex] == -1){

            nodes[nodeIndices[partIndex - 3]]
              .childNodes[nodeIndices[partIndex - 2]]
              .childNodes[nodeIndices[partIndex - 1]]
              .childNodes.push({
                isExpanded: false,
                id: part,
                label: part,
                childNodes: options
              })
            nodeIndices[partIndex] = nodes[nodeIndices[partIndex - 3]]
            .childNodes[nodeIndices[partIndex - 2]]
            .childNodes[nodeIndices[partIndex - 1]]
            .childNodes.length - 1

          }
        }
        if (partIndex == 4){
          nodeIndices[partIndex] = nodes[nodeIndices[partIndex - 4]]
            .childNodes[nodeIndices[partIndex - 3]]
            .childNodes[nodeIndices[partIndex - 2]]
            .childNodes[nodeIndices[partIndex - 1]]
            .childNodes.findIndex(x => x.label === part)
          if (nodeIndices[partIndex] == -1){

            nodes[nodeIndices[partIndex - 4]]
              .childNodes[nodeIndices[partIndex - 3]]
              .childNodes[nodeIndices[partIndex - 2]]
              .childNodes[nodeIndices[partIndex - 1]]
              .childNodes.push({
                isExpanded: false,
                id: part,
                label: part,
                childNodes: options
              })
          }

        }

      })
    })
    return nodes 
})




export default reportsSlice.reducer
