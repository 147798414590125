import  React, { useContext, useState, useEffect }  from 'react';
import { Spinner, Dialog, HTMLSelect, Icon,  Classes, Tag,  MenuItem, Card, Label, Button, ButtonGroup, Divider, Collapse } from "@blueprintjs/core";
import { MultiSelect2, ItemRenderer, ItemPredicate  } from "@blueprintjs/select";
import { DateRangePicker } from "@blueprintjs/datetime";
import { SearchQueryUsageDisplay } from './SearchQueryUsageDisplay'
import { QueryBook } from './QueryBook';

import { useAppSelector, useAppDispatch } from '../../app/hooks'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import type { Location} from '../types/Location';
import type { ArtifactType } from '../types/ArtifactType';
import { Visualizer } from './Visualizer'
import { selectedReportId, loadReportSearchQueryUsages, selectedReportSearchQueryUsages, updateCompQueries, updateName, updateQuery, updateDateRange, updateArtifactTypes, updateLocations, updateLocationPaths, loadAll, load, selectedReport, selectReports, selectSearchQueries, loadSearchQueries, selectedLocationPaths } from '../features/reportsSlice';
import { ReportSelector } from './ReportSelector'
import { reportTestData } from '../../test/reportTestData'
import { useLazyGetSearchQueryUsagesQuery, useLazyGetSearchQueriesQuery, useGetReportOptionsQuery } from '../services/reportApi'
import { updateLocations as updateLocationOptions, updateArtifactTypes as updateArtifactTypeOptions} from '../features/reportOptionsSlice'
import { featureConfig } from '../featureConfig'
import { LocationBrowser } from './LocationBrowser'
import { DarkModeContext } from '../../App'
 
enum Explorations {
  explore = "Explore",
  basic = "Basic",
  authors = "Authors",
  publishers = "Publishers",
  publicationTypes = "Publication Types",
  topics = "Topics"
}

const ArtifactTypeSelect = MultiSelect2.ofType<ArtifactType>()
const LocationSelect = MultiSelect2.ofType<Location>()



export const ReportConfigurator = () => {
  const report = useAppSelector(selectedReport);
  const reportId = useAppSelector(selectedReportId)
  const locationPaths = useAppSelector(selectedLocationPaths)

  useEffect(() => {console.log(locationPaths)}, [locationPaths])

  const {darkModeState, setDarkModeState} = useContext(DarkModeContext)

  const searchQueries = useAppSelector(selectSearchQueries)
  const searchQueryUsages = useAppSelector(selectedReportSearchQueryUsages)

  const locations = useAppSelector((state) => state.reportOptions.locations)
  const artifactTypes = useAppSelector((state) => state.reportOptions.artifactTypes)

  const { data, error, isLoading } = useGetReportOptionsQuery(null)


  const [ getSearchQueryData, searchQueryResponse, searchQueryLastPromiseInfo ] = useLazyGetSearchQueriesQuery()
  const [ getSearchQueryStateWaiting, setGetSearchQueryStateWaiting ] = React.useState(false)

  const [ getSearchQueryUsagesData, searchQueryUsagesResponse, searchQueryUsagesLastPromiseInfo ] = useLazyGetSearchQueryUsagesQuery()
  const [ getSearchQueryUsagesStateWaiting, setGetSearchQueryUsagesStateWaiting ] = React.useState(false)
  const [ getSearchQueryUsagesDataRequestIds, setGetSearchQueryUsagesDataRequestIds ] = useState<string[]>([])


  const [ queryBookOpen, setQueryBookOpen ] = useState(false)

  const [isComparisonsOpen, setIsComparisonsOpen] = React.useState(false)

  const dispatch = useAppDispatch()

  const artifactTypeTagRenderer = (item: ArtifactType) => item.name;
  
  const locationTagRenderer = (item: Location) => item.code;

  /***********
  /* Push location and artifact data to the State Store
  /*
  /**/
  useEffect(() => {
    if (data) {
      dispatch(updateLocationOptions(data.locations))
      dispatch(updateArtifactTypeOptions(data.artifact_types))
    }
  }, [data])

  /***********
  /* Push Search Queries to the State Store
  /*
  /**/
  useEffect(() => {
    if ( searchQueryResponse?.data && searchQueryResponse.status == "fulfilled") {
      dispatch(loadSearchQueries(searchQueryResponse.data)) 
      setGetSearchQueryStateWaiting(false)
    }
  }, [searchQueryResponse,  dispatch, setGetSearchQueryStateWaiting])


  /***********
  /* Fetch Search Queries if we don't have them yet
  /*
  /**/
  useEffect(() => {
    if (report && 
        (!searchQueries || 
          (typeof searchQueryLastPromiseInfo.lastArg == 'string' && searchQueryLastPromiseInfo.lastArg !== report.id)) && 

      !getSearchQueryStateWaiting) {
      if (searchQueryResponse.isUninitialized){
        setGetSearchQueryStateWaiting(true)
        getSearchQueryData(report.id)
      } else if (!searchQueryResponse.isLoading && !searchQueryResponse.isFetching && searchQueryResponse.isSuccess) {
        setGetSearchQueryStateWaiting(true)
        getSearchQueryData(report.id)
      }
    }

  }, [report, searchQueryResponse, getSearchQueryStateWaiting, setGetSearchQueryStateWaiting])



  /***********
  /* Push Search Query Usages to the State Store
  /*
  /**/
  useEffect(() => {
    // ignore changes to other vars if we don't have a fullfilled request
    if (searchQueryUsagesResponse?.status == "fulfilled" && searchQueryUsagesResponse?.originalArgs && searchQueryUsagesResponse?.data) {
      // if searchQueryUsages is empty definitely push data
      if (!searchQueryUsages && searchQueryUsagesResponse?.data) {
        dispatch(loadReportSearchQueryUsages([searchQueryUsagesResponse.originalArgs, searchQueryUsagesResponse.data]))
        if (searchQueryUsagesResponse.requestId) {
          const searchQueryUsagesDataRequestIds = [...getSearchQueryUsagesDataRequestIds, searchQueryUsagesResponse.requestId]
          setGetSearchQueryUsagesDataRequestIds(searchQueryUsagesDataRequestIds)
        }
        setGetSearchQueryUsagesStateWaiting(false)
      // else push new data if we have a new request id
      } else if (searchQueryUsagesResponse.requestId && !getSearchQueryUsagesDataRequestIds.includes(searchQueryUsagesResponse.requestId) && searchQueryUsagesResponse?.data) {
        dispatch(loadReportSearchQueryUsages([searchQueryUsagesResponse.originalArgs, searchQueryUsagesResponse.data]))
        setGetSearchQueryUsagesDataRequestIds([...getSearchQueryUsagesDataRequestIds, searchQueryUsagesResponse.requestId])
        setGetSearchQueryUsagesStateWaiting(false)
      }
      if ( searchQueryUsagesResponse.originalArgs == reportId) {
        setGetSearchQueryUsagesStateWaiting(false)
      }
    
    }
  }, [reportId, searchQueryUsages, searchQueryUsagesResponse, dispatch, setGetSearchQueryUsagesStateWaiting, getSearchQueryUsagesStateWaiting, getSearchQueryUsagesDataRequestIds, setGetSearchQueryUsagesDataRequestIds])
  

  /***********
  /* Fetch Search Query Usages if we don't have them yet
  /*
  /**/
  useEffect(() => {
    if (reportId && 
        (!searchQueryUsages || (searchQueryUsagesLastPromiseInfo.lastArg !== reportId && typeof searchQueryUsagesLastPromiseInfo.lastArg == 'string')) && 
        !getSearchQueryUsagesStateWaiting) {
      if (searchQueryUsagesResponse.isUninitialized){
        setGetSearchQueryUsagesStateWaiting(true)
        getSearchQueryUsagesData(reportId, true) //  true == prefer cached value
      } else if (!searchQueryUsagesResponse.isLoading && !searchQueryUsagesResponse.isFetching && searchQueryUsagesResponse.isSuccess) {
        setGetSearchQueryUsagesStateWaiting(true)
        getSearchQueryUsagesData(reportId, true) // true == prefer cached value

      }
    }

  }, [reportId, searchQueryUsages, searchQueryUsagesResponse, getSearchQueryUsagesStateWaiting, setGetSearchQueryUsagesStateWaiting, searchQueryUsagesLastPromiseInfo])






  
  const artifactTypeRenderer: ItemRenderer<ArtifactType> = (aType, { handleClick, handleFocus,  modifiers }) => {
      return (
        <MenuItem
          active={modifiers.active}
          key={aType.name}
          text={aType.name}
          onClick={handleClick}
        />
      );
  }
  const artifactTypeSelect = (item: ArtifactType, event?: React.SyntheticEvent<HTMLElement>) => {
    if (report && report.config ) {
      if (report.config.artifactTypes.indexOf(item) < 0) {
        dispatch(updateArtifactTypes([report.id, [...report.config.artifactTypes, item]]))
      }
    }
  }



  const locationRenderer: ItemRenderer<Location> = (location, { handleClick, handleFocus, modifiers }) => {
      return (
        <MenuItem
          active={modifiers.active}
          key={location.code}
          text={location.name}
          label={location.code}
          onClick={handleClick}
        />
      );
  }

  const locationPredicate: ItemPredicate<Location> = (query: string, item: Location, index?: undefined | number, exactMatch?: undefined | false | true): boolean => {
    if (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.code.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      return true
    } else {
      return false
    }
  }

  const artifactTypePredicate: ItemPredicate<ArtifactType> = (query: string, item: ArtifactType, index?: undefined | number, exactMatch?: undefined | false | true): boolean => {
    if (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      return true
    } else {
      return false
    }
  }

  const locationSelect = (item: Location, event?: React.SyntheticEvent<HTMLElement>) => {
    if (report && report.config ) {
      if (report.config.locations.indexOf(item) < 0) {
        dispatch(updateLocations([report.id, [...report.config.locations, item]]))
      }
    }
  }
  const handleLocationPaths = (items: string[]) => {
    if (report && report.config ) {
      dispatch(updateLocationPaths([report.id, items]))
    }
  } 
  const locationRemover = (item: Location, index: number) => {
    if (index !== null && report && report.config) {
      if (report.config.locations.indexOf(item) == index) {
        const payload = [ ...report.config.locations]
        payload.splice(index, 1)
        dispatch(updateLocations([report.id, payload]))
      } else {
        console.log("index mis match")
      }
    } 
  }

  const artifactTypeRemover = (item: ArtifactType, index: number) => {
    if (index !== null && report && report.config) {
      if (report.config.artifactTypes.indexOf(item) == index) {
        const payload = [ ...report.config.artifactTypes]
        payload.splice(index, 1)
        dispatch(updateArtifactTypes([report.id, payload]))
      } else {
        console.log("index mis match")
      }
    } 
  }


  const reportDateRange = () :[Date|null, Date|null]=> {
    if (report && report.config) {
      let startDate = report.config.dateRange[0] === null ? null : new Date(report.config.dateRange[0]);
      let endDate = report.config.dateRange[1] === null ? null : new Date(report.config.dateRange[1]);
      return [startDate, endDate]
    } else {
      return [null,null]
    }
  }

  const dateChanger = (dateRange: [Date|null, Date|null]) => {
    if (report && report.config) {

      const startDate = dateRange[0] == null ? null : dateRange[0].toUTCString()
      const endDate = dateRange[1] == null ? null : dateRange[1].toUTCString()
      dispatch(updateDateRange([report.id, [startDate, endDate]]))
    }
  }

  const queryChanger = (event: any) => {
     if (report && report.config) {
        dispatch(updateQuery([report.id, event.target.value]))
    }
  }

  const compQueryChanger = (index: number, query: string) => {
    let comparisons:string[] = [...(report?.config?.comparisons || [])]
    comparisons[index] = query
    if (report?.id) {
      dispatch(updateCompQueries([report.id, comparisons]))
    }
  }

  const addCompQuery = () => {
    let comparisons:string[] = report?.config?.comparisons || []
    if(report?.id){
      dispatch(updateCompQueries([report.id, [...comparisons, '']]))
    }
  }

  const removeCompQuery = (index) => {
    let comparisons:string[] = [...(report?.config?.comparisons || [])]
    comparisons.splice(index, 1)
    if (report?.id) {
      dispatch(updateCompQueries([report.id, comparisons]))
    }
  }

  const nameChanger = (event: any) => {
     if (report && report.config) {
        dispatch(updateName([report.id, event.target.value]))
    }
  }

  const exploreQuery = (event) => {}

  const exploreComparison = (event, value) => {}

  const user = useAppSelector((state) => state.userData.user)

  if (report) {
    return (
      <div className="report-configurator">
        { report && user &&
        <div className="actualReport">
          <Divider/>
          <h4 className="bp4-heading">Filter Configuration</h4>

          <Row>
            <Col md="12" lg="6">
              <Label>
                Name
                <input className={Classes.INPUT + " bp4-fill"} type="text" placeholder="Name..." dir="auto" value={report.config.name  ? report.config.name : ''} onChange={nameChanger}/>
              </Label>
              <Label className="label-v-adjacent">
                Markets
              </Label>
              { locationPaths === null && 
              <LocationSelect
                items={locations}
                itemRenderer={locationRenderer}
                onItemSelect={locationSelect}
                tagRenderer={locationTagRenderer}
                selectedItems={report.config.locations}
                itemPredicate={locationPredicate}
                onRemove={locationRemover}
                fill={true}
              />
              }
              { locationPaths && locationPaths?.length >= 0 &&
                <LocationBrowser root={`/${featureConfig?.filterModules[user.filterModule || 0]}/`} onSelectionChange={handleLocationPaths} selectedPaths={locationPaths}/>
              }
              <Label className="label-v-adjacent">
                Media Types
              </Label>
                <ArtifactTypeSelect
                  items={artifactTypes}
                  itemRenderer={artifactTypeRenderer}
                  onItemSelect={artifactTypeSelect}
                  tagRenderer={artifactTypeTagRenderer}
                  itemPredicate={artifactTypePredicate}
                  selectedItems={report.config.artifactTypes}
                  onRemove={artifactTypeRemover}
                  fill={true}

                />
            </Col>
            <Col md="12" lg="6">
              <Label>
                Date Range
              </Label>
                <DateRangePicker
                  value={reportDateRange()}
                  onChange={dateChanger}
                />
            </Col>
            <Col md="12">
              <Row>
                <Col>
                  <Label>
                    Queries <Button icon="manually-entered-data" small={true} onClick={() => setQueryBookOpen(true)}/>
                  </Label>
                  { getSearchQueryUsagesStateWaiting &&
                    <Spinner />
                  }
                  <Dialog className={`${darkModeState ? 'bp4-dark' : ''} query-book-dialog`} lazy={true} usePortal={true} isOpen={queryBookOpen} onClose={() => {setQueryBookOpen(false)}} title="Query Book">
                    <QueryBook />
                  </Dialog>
                  { searchQueries && searchQueryUsages?.map((squ) => 
                    <SearchQueryUsageDisplay order={squ.order} active={squ.active} sQID={squ.search_query_id}/>
                  )}
                </Col>
              </Row>
            </Col>
            { (report.config.query && report.config.query != '' && searchQueryUsages?.length == 0) &&
            <Col md="12">
              <Row>
                <Col>
                  <Label>
                    Query
                    <input className={Classes.INPUT + " bp4-fill"} type="text" placeholder="Search..." dir="auto" value={report.config.query  ? report.config.query : ''} onChange={queryChanger}/>
                  </Label>
                </Col>
                { featureConfig.exploreMenuActivated &&
                  <Col>
                    <Label>
                      Explore
                      <HTMLSelect
                        options={Object.keys(Explorations).map(key => {
                          return {value: key, label: Explorations[key]}
                        })} 
                        defaultValue="explore"
                        minimal={false} onChange={(event) => exploreQuery(event)} />
                    </Label>
                  </Col>
                }
              </Row>
              <Row>
                <Col>
                  <Button onClick={() => setIsComparisonsOpen(!isComparisonsOpen)}
                    minimal={true}
                  >
                    <span>Comparisons </span><Icon icon={ isComparisonsOpen ? "chevron-down" : "chevron-right"}/>
                  </Button>
                </Col>
              </Row> 
              <Collapse isOpen={isComparisonsOpen}>
                { report.config.comparisons?.map((comp_q, index) =>
                  <Row key={`${index}-comp_q`}>
                    <Divider />
                    <Col>
                      <input className={Classes.INPUT + " bp4-fill"} type="text" placeholder="Search..." dir="auto" value={comp_q}  onChange={(event) => compQueryChanger(index, event.target.value)}/>
                    </Col>
                    <Col>
                      <ButtonGroup>
                        { featureConfig.exploreMenuActivated && 

                          <HTMLSelect
                            options={Object.keys(Explorations).map(key => {
                              return {value: key, label: Explorations[key]}
                            })} 
                            minimal={false} onChange={(event) => exploreComparison(index, event)} />
                        }
                        <Button icon="trash" intent="danger" onClick={() => removeCompQuery(index)}/>
                      </ButtonGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col> 
                    <Button text="+" minimal={true} onClick={addCompQuery}/>
                  </Col>
                </Row>
              </Collapse>
            </Col>
            }
          </Row>
        </div>
        }
      </div>
    );
  } else {
    return (
      <span></span>
    );
  }
}
