import  React, { useEffect }  from 'react';
import { Button, Divider, Card, Spinner, Callout } from "@blueprintjs/core";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { Report } from './Report';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { mySelectedOrg} from '../features/orgsSlice';
import { selectedReportSearchQueryUsages, removeReport, updateReportData, updateStatus, load, loadData, loadAll, selectedReport, selectedReportId, selectedReportDataStatus, selectReports } from '../features/reportsSlice';

import { setModule } from '../features/userSlice';

import { useUpdateSearchQueryUsagesMutation, useDeleteReportMutation, useCopyReportMutation, useLazyGetDataQuery, useLazyGetDataStatusQuery, useRunReportMutation, useUpdateReportMutation, useCreateReportMutation, useGetMyReportsQuery } from '../services/reportApi'

import { selectDashboards, loadDashboards } from '../features/dashboardSlice'
import { useGetDashboardsQuery } from '../services/dashboardApi'

import { DashboardSelector } from './DashboardSelector'
import { ReportSelector } from './ReportSelector'
import { useNavigate, Link } from "react-router-dom";
import { Breadcrumbs } from './Breadcrumbs'

import { featureConfig } from '../featureConfig'

export const Home = () => {
  const user = useAppSelector((state) => state.userData.user)
  const myOrg = useAppSelector(mySelectedOrg)

  const dispatch = useAppDispatch()

//  const { data, error, isLoading } = useGetMyReportsQuery(null)
//  const { data: dashboardsData, error: dashboardsError, isLoading: dashboardsIsLoading } = useGetDashboardsQuery(null)

  const navigate = useNavigate();

//  const reports = useAppSelector(selectReports);
//  const dashboards = useAppSelector(selectDashboards)

  const handleModule = (index) => {
    dispatch(setModule(index))
  }

//  useEffect(() => {
//    if (data) {
//      dispatch(loadAll(data)); 
//    }
//  }, [data, dispatch])

  const breadcrumbs =  [
    {href: "/", text: 'Home'}
  ]

//  useEffect(() => {
//    if(dashboardsData) {
//      dispatch(loadDashboards(dashboardsData)) 
//     }
//  }, [dashboardsData, dispatch])



  return (
    <Row>
      <Col>
        <Card>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Divider />
        { user && myOrg &&
          <div>
            <Row>
              <Col md={6}>
                <h3>Product Updates</h3>
                <p>Oct 13, 2022</p>
                <p>We're busy improving our product. We are renaming "Reports" to "Data Filters" in order to make them more descriptive of their function. They will still have the ability to create small visualizations and the abilty to help you dig into the raw artifacts behind the visuals. However, we will soon be launching "Dashboards" which will allow users to build charts that mix and match data from multiple different "Data Filters." This is our next step along the way of producing data rich visuals that allow our customers to more easily track and understand the locations they are interested in.</p>
              </Col>
              <Col md={6}>
                <h3>Help Center</h3>
                <h4>Need assistance?</h4>
                <p>Feel free to email us at <a href="mailto:support@filterlabs.ai">support@filterlabs.ai</a></p>
              </Col>
            </Row>
            <Divider />
            { user.filterModule != undefined && user.filterModule != null &&
            <Row>
              <Col xs={12} md={6}>
                <h3><Link to={"/dashboards"}>Dashboards (BETA)</Link></h3>
                <h5>Visualize What's Important</h5>
                <DashboardSelector handleSelect={() => {
                  navigate('/dashboards')
                }} />
              </Col>
              <Col xs={12} md={6}>
                <h3><Link to={'/data-filters'}>Data Filters</Link></h3>
                <h5>(Formerly Reports) - Find the Data You Need</h5>
                <ReportSelector handleSelect={() => {
                  navigate('/data-filters')
                }}/>
              </Col>
            </Row>
            }
            { (user.filterModule == undefined || user.filterModule == null )&&
            <div>
            <Row>
              <Col>
                <h3>Choose your Discourse Module</h3>
              </Col>
            </Row>
            <Row>
              { featureConfig.filterModuleNames?.map( (key, index) => (
                <Col sm="3"> 
                  <Button className="module-choice" fill={true} onClick={() => handleModule(index)}>{key}</Button>
                 </Col>
              ))}
            </Row>
            </div>
            }
          </div>
        }
        </Card>
      </Col>
    </Row>
  );
}
