import { featureConfig } from  '../featureConfig'
import { selectUser } from '../features/userSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useRef, useState, useEffect } from 'react';

export const Dashboard2Space = () => {

  const [grafLoggedIn, setGrafLoggedIn] = useState(false);
  const grafLogin = useRef<Window|null>();

  const user = useAppSelector((state) => state.userData.user)

  let loginPopup:any = null
  useEffect(() => {
    const listener = window.addEventListener("message", (event) => {
      if (event.data.type === 'loginReport'){
        if(event.data.data === true) {
          setGrafLoggedIn(true)
          if(grafLogin.current !== null && grafLogin.current !== undefined){
            grafLogin.current.close()
          }

        }
      }
      console.log(event)
    }, false)
//    return () => window.removeEventListener('message', listener)
// window.opener.postMessage({data: window.grafanaBootData.user.isSignedIn, type: 'loginReport'}, "*")
  }, [])

  
  const login = () => {

    grafLogin.current = window.open(featureConfig.grafana_url, 'loginWindow', 'popup=yes')

  }
  return ( 
    <div>
        <iframe src={`${featureConfig.grafana_url}`} className="talisman-embed" sandbox="allow-storage-access-by-user-activation
                 allow-scripts
                 allow-same-origin
                 allow-forms"/>
    </div>
  );
}
