import  React, { useEffect, useState }  from 'react';
import { Divider, Label, HTMLSelect, Classes,  ButtonGroup, Button, Card, HTMLTable } from "@blueprintjs/core";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { selectMe, selectOrgUsers, loadOrgUsers, loadMe, loadMeOrgs, selectMyOrg, mySelectedOrg} from '../features/orgsSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useCreateOrgUserMutation, useLazyGetMeQuery, useLazyGetMeOrgsQuery, useLazyGetOrgUsersQuery, useDelOrgUserMutation } from '../services/usersOrgsApi';
import { OrgUser } from '../types/OrgUser'
import { UserInvite } from '../types/UserInvite'
import { selectedReportUsers } from '../features/reportsSlice'
import { Breadcrumbs } from './Breadcrumbs'

export const Organizations = () => {
  const user = useAppSelector((state) => state.userData.user)
  const orgUsers = useAppSelector(selectOrgUsers)
  const dispatch = useAppDispatch()
  const myOrg = useAppSelector(mySelectedOrg)

  const isSamlOrg = () => {
    return myOrg?.org.hasOwnProperty('samlorg') == true
  }
  const me = useAppSelector(selectMe)

  const [newUserOpen, setNewUserOpen] = useState(false)
  const [newUserEmail, setNewUserEmail] = useState('')
  const [newUserRole, setNewUserRole] = useState('user')

  const [ getOrgUsers, orgUsersResponse, orgUsersLPI ] = useLazyGetOrgUsersQuery()

  useEffect(() => {
    if ((!orgUsersResponse || orgUsersResponse.status !== 'fulfilled') && myOrg && myOrg.role == 'admin') {
      getOrgUsers(myOrg.org.uuid)
    }
    if (orgUsersResponse && orgUsersResponse.status == 'fulfilled') {
      dispatch(loadOrgUsers(orgUsersResponse.data))
    }
  }, [orgUsersResponse, getOrgUsers, myOrg])

  const handleOpenCreateUserForm = () => {
    setNewUserOpen(true)  

  }

  const cancelCreate = () => {
    setNewUserOpen(false)
    setNewUserRole('user')
    setNewUserEmail('')

  }

  const [ createOrgUserApi, {isLoading: createOrgUserLoading} ] = useCreateOrgUserMutation()

  const createOrgUser = (uuid: string) => {
    console.log({
      uuid: uuid,
      email: newUserEmail,
      role: newUserRole
    })

    createOrgUserApi({
      uuid: uuid,
      email: newUserEmail,
      role: newUserRole
    })
    cancelCreate();
  }

  const [ delOrgUserApi, {isLoading: delOrgUserLoading} ] = useDelOrgUserMutation()

  const delOrgUser = (org_uuid:string, user_uuid:string) => {
    delOrgUserApi({
      org_uuid: org_uuid,
      user_uuid: user_uuid
    })
  }




  const breadcrumbs =  [
    {href: "/", text: 'Home'},
    {href: "/orgs", text: 'My Organization'}
  ]

  return (
    <div>
      <Row>
        <Col>
        <Card>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Divider />
          { myOrg &&
            <div>
              <h5 className="bp4-heading">{ myOrg.org.name }</h5>
              { me && orgUsers && orgUsers.length > 0 &&
                <div>
                  <h6>Manage Users</h6>
                  <HTMLTable className="bp4-html-table full-width" bordered={true} interactive={true} striped={true}>
                    <thead>
                      <tr>
                        <th>Email</th><th>Role</th><th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                  { orgUsers.map((o_user) => ( 
                    <tr>
                      <td>{ o_user.user.email }</td>
                      <td>{ o_user.role }</td>
                      <td>
                        { o_user.user.uuid !== me.uuid &&
                          <Button icon="trash" onClick={() => delOrgUser(myOrg.org.uuid, o_user.user.uuid)}/>
                        }
                      </td>
                    </tr>
                  ))}
                    </tbody>
                  </HTMLTable>
                  <Divider />
                  { isSamlOrg() &&
                    <div>
                      <h6>Manage SAML Invites</h6>
                      <HTMLTable className="bp4-html-table full-width" bordered={true} interactive={true} striped={true}>
                        <thead>
                          <tr>
                            <th>Email</th><th>Role</th><th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                      { myOrg.org.invites?.map((o_user: UserInvite) => ( 
                        <tr>
                          <td>{ o_user.email }</td>
                          <td>{ o_user.role }</td>
                          <td>
                            <Button icon="trash" onClick={() => delOrgUser(myOrg.org.uuid, o_user.email)}/>
                          </td>
                        </tr>
                      ))}
                        </tbody>
                      </HTMLTable>
                    </div> 
                  }
                  <Button icon="add" onClick={handleOpenCreateUserForm} />
                </div>
              }
            </div>
          }
        </Card>
        </Col>
      </Row>
      { newUserOpen && 
        <Row className="top-margin-10">
          <Col>
          <Card>
            <h5 className="bp4-heading">{isSamlOrg() ? "Invite" : "Create"} New User</h5>
            <Row>
              <Col>
                <Label>Email Address
                  <input className={Classes.INPUT + " bp4-fill"} placeholder="Email Address" dir="auto" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
                </Label>

                <Label>Role
                  <HTMLSelect className="bp4-fill" options={
                    [
                      {label: "User: Can view and Edit Reports", value: 'user'},
                      {label: "Admin: Can also manage organization's users", value: 'admin'}
                    ]
                  }
                  value={newUserRole}
                  onChange={(e)=> setNewUserRole(e.currentTarget.value)}
                  />
                </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup className="float-right">
                  { myOrg?.org?.uuid &&
                    <Button text={isSamlOrg() ? "Invite" : "Create"} className="bp4-intent-primary"
                      onClick={() => createOrgUser(myOrg.org.uuid)}
                    />
                  }
                  <Button text="Cancel" onClick={cancelCreate} className="bp4-intent-danger"/>
                </ButtonGroup>
              </Col>
            </Row>
          </Card>
          </Col>
        </Row>
      }
    </div>
  );
}
