export const featureConfig = {
  exploreMenuActivated: process.env.REACT_APP_EXPLORE_ACTIVATED == "true" ? true : false,
  reduxDevToolsEnabled: process.env.REACT_APP_REDUX_DEV_TOOLS_ENABLED == "true" ? true : false,
  grafana_url: process.env.REACT_APP_GRAFANA_URL,
  chartJsColors: "tableau.Classic20",

  surpriseLabels: process.env.REACT_APP_SURPRISE_LABELS ? process.env.REACT_APP_SURPRISE_LABELS.split(',') : [],
  filterModules: process.env.REACT_APP_FILTER_MODULES ? process.env.REACT_APP_FILTER_MODULES.split(',') : [],
  filterModuleNames: process.env.REACT_APP_FILTER_MODULE_NAMES ? process.env.REACT_APP_FILTER_MODULE_NAMES.split(',') : [], 
}
